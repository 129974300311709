import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout.component'
import { FaArrowLeft } from 'react-icons/fa'
import SEO from '../components/seo.component'

export const query = graphql`
  query ($slug: String) {
    allImageSharp {
      edges {
        node {
          id
          fluid(quality: 100, maxWidth: 4160) {
            src
          }
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        subtitle
        thumbnail {
          childImageSharp {
            original {
              src
            }
          }
        }
        date
      }
      html
    }
  }
`

const BlogPage = (props) => {
  return (
    <Layout>
      <SEO title={props.data.markdownRemark.frontmatter.title} />

      <section className="BlogPage">
        <div className="BlogPage__container">
          <div className="Header__line"></div>
          <div className="Header__text">
            <small className="Header__date">
              {props.data.markdownRemark.frontmatter.date}
            </small>
          </div>
          <div className="BlogPage__header">
            <img
              className="BlogPage__img"
              src={
                props.data.markdownRemark.frontmatter.thumbnail.childImageSharp
                  .original.src
              }
            />
            <div>
              <h1 className="Heading--primary">
                {props.data.markdownRemark.frontmatter.title}
              </h1>
              <div className="BlogPage__line">
                <h2 className="Heading--secondary">
                  {props.data.markdownRemark.frontmatter.subtitle}
                </h2>
              </div>
            </div>
          </div>

          <div
            className="BlogPage__content"
            dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }}
          ></div>
          <Link to="/blog" className="BlogPage__back">
            <FaArrowLeft /> Back to updates
          </Link>
        </div>
      </section>
    </Layout>
  )
}

export default BlogPage
